.rubberBand-Container{
    top: 0px;
    left: 0px;
    pointer-events:none;
    position:absolute;
}
.rubberBand{
    fill:none;
    pointer-events:none;
    stroke:rgba(193,35,124,1);
    stroke-width:2;
    fill-opacity:0.0;
    stroke-opacity:1;
    stroke-dasharray:5
}

.rubberBandHandle{
    fill:white;
    pointer-events:fill;
    stroke:rgba(193,35,124,1);
    stroke-width:1;
    fill-opacity:1;
    stroke-opacity:0.9;
}
.rubberBandHandleRotate{
    fill:white;
    pointer-events:fill;
    stroke:rgba(193,35,124,1);
    stroke-width:3;
    fill-opacity:1;
    stroke-opacity:0.9;
}

.rubberConnector{
    pointer-events:fill;
    fill:white;
    stroke:rgba(193,35,124,1);
    stroke-width:1;
    fill-opacity:1;
    stroke-opacity:0.9;
}