
.background-main-container{
  display: flex;
  flex-direction: column;
  gap:10px;
}
.background-sample-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  width: 300px;
  gap: 10px;
  align-content: flex-start;
  width: 300px;
}
.background-sample{
  width:40px;
  height: 40px;
  border-radius: 50%;

}