.svg_rotate {
  animation: rotation 8s infinite linear;
  transform-origin: 50% 50%;
}

@keyframes rotation {
  from {
    
      transform: rotate(0deg);
  }
  to {
 
      transform: rotate(359deg);
  }
}


.svg_arrow {
  animation: arrow   3s infinite ease-in-out;
  transform-origin: 50% 50%;
}

@keyframes arrow {
  0%   {transform: translateY(4px)}
  33%  {transform: translateY(0px);}
  100% {transform: translateY(4px)}
 
}

.svg_arrow {
  animation: arrow   3s infinite ease-in-out;
  transform-origin: 50% 50%;
}

.svg_cloud {
  animation: scale   3s infinite ease-in-out;
  transform-origin: 50% 50%;
}

@keyframes scale {
  0%   {transform: scale(1)}
  33%  {transform: scale(1.1)}
  100% {transform: scale(1)}
 
}

.logo-animated {
	-webkit-animation: flip-vertical-left 4s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
	        animation: flip-vertical-left 4s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
}

@-webkit-keyframes flip-vertical-left {
    0% {
      -webkit-transform: rotateY(0);
              transform: rotateY(0);
    }
    50% {
        -webkit-transform: rotateY(-180deg);
                transform: rotateY(-180deg);
      }
    100% {
      -webkit-transform: rotateY(-360deg);
              transform: rotateY(-360deg);
    }
  }
  @keyframes flip-vertical-left {
    0% {
      -webkit-transform: rotateY(0);
              transform: rotateY(0);
    }
    50% {
      -webkit-transform: rotateY(-180deg);
              transform: rotateY(-180deg);
    }
    50% {
        -webkit-transform: rotateY(-360deg);
                transform: rotateY(-360deg);
      }
  }