
.editor-container-main{
  background-color: white;
  padding: 20px;
  gap: 30px;
  height: 350px;
  display:flex;
  justify-content: center;
  align-items: center;
  border-style: dashed;
  border-color: gray;
  border-width: 1px;
  border-radius: 10px;
}
.editor-container{
  display:flex;
  position:relative;
  flex-direction: column;
  border-radius: 5%;
  background-color: white;
  /* padding: 53px; */
  width: 297px;
  height: 297px;
}

.editor-mask{
  position:absolute;
  top:0px;
  left: 0px;
  width:100%;
  height: 100%;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: gray;
  background-color: rgb(114, 123, 121,0.3);
  mask-image: url(./assets/mask.png);
  -webkit-mask-image: url(./assets/mask.png);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  pointer-events: none;

}

.editor-img-container{
  /* border: solid 5px maroon; */
  position:absolute;
  border-radius: 50%;
  width: 203px;
  height: 205px;
  top:50px;
  left:50px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.img-grid{
  position:absolute;
  top:0px;
  left: 0px;
  width:100%;
  height: 100%;
  border-radius: 10px;
  pointer-events: none;
  opacity: 0.5;
}
.editor-title{
  background-color:#228be6 ;
  border-radius:20px;
}