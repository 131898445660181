

.selfy-img-container{
  /* border: solid 5px maroon; */
  position:relative;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  background-image: linear-gradient(to right, rgb(249, 83, 198), rgb(185, 29, 115));
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor:pointer;
}
.selfy-img{  
  position:absolute;
  transform-origin: 0% 0%;
  width: 200px;

}
.selfy-img-offset{  
  top:-50px;
  left: -50px;
}


.download-animated {
	-webkit-animation: flip-vertical-left 4s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
	        animation: flip-vertical-left 4s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
}

@-webkit-keyframes flip-vertical-left {
    0% {
      -webkit-transform: rotateY(0);
              transform: rotateY(0);
    }
    50% {
        -webkit-transform: rotateY(-180deg);
                transform: rotateY(-180deg);
      }
    100% {
      -webkit-transform: rotateY(-360deg);
              transform: rotateY(-360deg);
    }
  }
  @keyframes flip-vertical-left {
    0% {
      -webkit-transform: rotateY(0);
              transform: rotateY(0);
    }
    50% {
      -webkit-transform: rotateY(-180deg);
              transform: rotateY(-180deg);
    }
    50% {
        -webkit-transform: rotateY(-360deg);
                transform: rotateY(-360deg);
      }
  }

  .main-container{
    position: relative
  }
  .main-container  .dowload-button{
    visibility: hidden;
  }
  .main-container:hover .dowload-button{
    visibility: visible;
}

  .dowload-button{
    position: absolute;
    bottom: 5px;
    right: 5px;
    border-radius: 50%;
    background-color: #228be6;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
   cursor:pointer;
  }
  .dowload-button:active{
    transform: scale(1.1);
  }